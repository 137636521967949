import React, { useState,useEffect } from "react";
import "./ProfileForm.css"; 
import axios from "axios";
import jQuery from 'jquery';
import { Box } from "@mui/material";

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'x-csrftoken'
axios.defaults.withCredentials=true;
const ProfileForm = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPayOpen, setIsPayOpen] = useState(false);
  const [paymentmethod, setpaymentmethod] = useState("");
  const [subscriptionlen, setsubscriptionlen] = useState("");
  const [subscriptiontype, setsubscriptiontype] = useState("");
  const [amount, setamount] = useState(0);
  const [i, setindex] = useState(0);
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [expiredate, setexpiredate] = useState(0);
  const [userinfo,setUserinfo] = useState([]);
  
  const get_usr=()=>{
    axios.get('https://codepro.insa.gov.et/profile/',{'withCredentials':true}).
    then((res)=>{
        console.log(res.data)
        setUserinfo(res.data)
        console.log(Date())
        console.log(userinfo)  
        setfirstname(userinfo[0])
        setlastname(userinfo[1])
        setemail(userinfo[2])
        setphone(userinfo[3])
        setpaymentmethod(userinfo[4]['paymentmethod'])
        setsubscriptionlen(userinfo[4]['subscriptionlen'])
        setsubscriptiontype(userinfo[4]['subscriptiontype'])
        setamount(userinfo[4]['amount'])
        console.log(Date(userinfo[4]['paymentdate'])) 
    }).catch((err)=>{console.log(err)});
  }
  useEffect(()=>{
    get_usr()
   },[]);

   const get_cookie = (name) => {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = jQuery.trim(cookies[i]);
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
  };

  let config={
    headers:{
        'X-CSRFToken':get_cookie('csrftoken')
      },
    withCredentials:true,
    }
   const openPay = (e) => {
    console.log("from openpay")
    console.log(i)
    setIsPayOpen(true);
   };

   const closePay = () => {
    setIsPayOpen(false);
   };


   const openModal = () => {
    setIsModalOpen(true);
   };

   const closeModal = () => {
    setfirstname("")
    setlastname("")
    setemail("")
    setphone("")
    setIsModalOpen(false);
   };

   const submitEdit = function(e) {
    console.log(get_cookie('csrftoken'))
    e.preventDefault();
    
    axios.post("https://codepro.insa.gov.et/edituser/",{
       
        firstname:firstname,
        lastname:firstname,
        email:email,
        phone:phone,
        
    },config)
    .then((res) => {
        console.log(res.data)
        setIsModalOpen(false);
        get_usr()
        
    })
    .catch((err)=>{console.log(err.data)});
  };

  const submitPay=function(e){
    console.log(e)
   
    e.preventDefault();
      console.log(userinfo[i]['id'])
      axios.post("https://codepro.insa.gov.et/payregister/"+userinfo[i]['id'],{
        
        paymentmethod: paymentmethod,
        subscriptionlen: subscriptionlen,
        subscriptiontype :subscriptiontype,
        amount: amount,
      },config)
      .then((res)=>{
        setIsPayOpen(false);
        axios.get('https://codepro.insa.gov.et/profile/',{'withCredentials':true}).
        then((res)=>{
            console.log(res.data)
            setUserinfo(res.data)
            console.log(Date())
            console.log(userinfo)  
            console.log(Date(userinfo[4]['paymentdate'])) 
        }).catch((err)=>{console.log(err)});
       
          
      })
      .catch((err) =>{console.log(err.data)});

  };

  const calculatePrice=()=>{
    setsubscriptionlen(document.querySelector('#subscription-length').value)
    setsubscriptiontype(document.querySelector('#subscription-type').value)
    setpaymentmethod(document.querySelector('#payment-method').value)
    let amounts
    console.log("reach here in calculate price")
    if (document.querySelector('#subscription-type').value==="Standard"){
        switch (document.querySelector('#subscription-length').value){
            case "Daily":
                setamount(1000*1);
                amounts=document.querySelector('#amount');
                amounts.value=amount.toString()
                break
            case "Monthly":
                setamount(1000*2);
                amounts=document.querySelector('#amount')
                amounts.value=amount.toString()
                break
            case "Annual":
                setamount(1000*3);
                amounts=document.querySelector('#amount')
                amounts.value=amount.toString()
                break
            default:
                setamount(1000*1);
                amounts=document.querySelector('#amount')
                amounts.value=amount.toString() 
                break           
        }
    }
    else if (document.querySelector('#subscription-type').value==="Premium"){
        switch (document.querySelector('#subscription-length').value){
            case "Daily":
                setamount(2000*1);
                amounts=document.querySelector('#amount');
                amounts.value=amount.toString()
                break
            case "Monthly":
                setamount(2000*2);
                amounts=document.querySelector('#amount');
                amounts.value=amount.toString()
                break
            case "Annual":
                setamount(2000*3);
                amounts=document.querySelector('#amount');
                amounts.value=amount.toString()
                break
            default:
                setamount(2000*1);    
                amounts=document.querySelector('#amount');
                amounts.value=amount.toString()
                break        
        }
    }
    else{
        setamount(0)
    }
    console.log(amount)
  };

  return (
    <div className="main_container_profile">
      <Box>  
       <div className="settings-main">
            <div className="profile-header">
                <h1>Personal information</h1>
            </div>
            <form className="profile-form" id="profile-form">
                    <div className="profile-group">
                        <label for="first-name">Full Name</label>
                        <label>{userinfo[0]}</label>
                    
                        <label for="email">Email</label>
                        <label>{userinfo[2]}</label>
                        <label for="phone">Phone</label>
                        <label>{userinfo[3]}</label> 
                    </div>
                <div className='form-row'>
                   
                    <div className="profile-gourp">
                        <button type="button" className="edit-btn2" id="edit-btn2" onClick={openModal}>Edit information</button>
                        
                    </div>
                </div>
            </form>
        </div>    

            <div class="profile-header">
                <h1>Payment status</h1>
            </div>

            <table className="file-table1">
                <thead>
                    <tr>
                        <th>Subscription type</th>
                        <th>Subscription length</th>
                        <th>Payment method</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {(()=>{
                        console.log("before ")
                        console.log(userinfo)
                        for (let i=4;i<userinfo.length;i++){
                                console.log("in for loop")
                                return(
                                    <>
                                    <tr>
                                        <td>{userinfo[i]['subscriptiontype']}</td>
                                        <td>{userinfo[i]['subscriptionlen']}</td>
                                        <td>{userinfo[i]['paymentmethod']}</td>
                                        <td>{userinfo[i]['amount']}</td>
                                        <td></td>
                                        <td><button type='button' className='edit-btn3' id='edit-btn' onMouseOver={e=>setindex(i)} onClick={()=>openPay(userinfo[i]['id'])} >Upgrade</button></td>
                                    </tr>
                                    </>
                                )
                            }
                            } 
                        ) ()    
                    }
                </tbody>
            </table>      
        </Box>
            {isModalOpen && (
            <div className="personal-overlay">
              <div className="personal-container">
                <h2>Edit info</h2>
                <form onSubmit={submitEdit} >
                <div className="profile-group">
                    <label>Full Name</label>
                    <input type="text" className="firstnamee" name="firstname" placeholder={userinfo[0]} onChange={e=>setfirstname(e.target.value)} />
                </div>
                
                <div className="profile-group">
                    <label>Email</label>
                    <input type="email" className="emaile" name="email"  placeholder={userinfo[2]} onChange={e=>setemail(e.target.value)}/>
                </div>
                <div className="profile-group">
                    <label>Phone</label>
                    <input type="phone" id="phonee" name="phone"  placeholder={userinfo[3]} onChange={e=>setphone(e.target.value)}/>
                </div>
                <div className="profile-actions">
                    <button className='cnclbtn' type="button" onClick={closeModal}>Cancel</button>
                    <button className='editbtn' type="submit" onClick={submitEdit}>Save</button>
                </div>
                </form>
              </div>
            </div>
            )}

           {isPayOpen && (
            <div className="personal-overlay">
              <div className="payment-container">
                <h2>Update Payment</h2>
                <form onSubmit={submitPay}>
                    <div className="profile-group">
                    <label for="subscription-type" class="form-label">
                        Subscription Type
                    </label>
                    <select id="subscription-type" name="subscriptiontype" onChange={calculatePrice}>
                        
                        <option value="Standard">Standard</option>
                        <option value="Premium">Premium</option>
                    </select>
                    </div>
                    <div className="profile-group">
                    <label for="subscription-length" class="form-label">
                        Subscription Length
                    </label>
                    <select id="subscription-length" name="subscriptionlen" onChange={calculatePrice}>
                        
                        <option value="Daily">Daily</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Annual">Annual</option>
                    </select>
                    </div> 
                    <div className="profile-group">                   
                    <label for="payment-method" class="form-label">
                        Payment Method
                    </label>
                    <select id="payment-method" name="payment-method" onChange={calculatePrice}>
                        <option value="chapa">Chapa</option>
                        <option value="manual">Manual</option>
                    </select>
                    </div>
                    <div className="profile-group"> 
                    <label for="amount" class="form-label">
                        Amount
                    </label>
                    <input type="text" id="amount" name="amount" readOnly />
                    </div>
                    <div className="profile-actions">
                        <button type="button"  className='cnclbtn' onClick={closePay}>Cancel</button>
                        <button type="submit" className='editbtn'>Update</button>
                    </div>
                </form>
              </div>
            </div>
            )}
    </div>
  );
};

export default ProfileForm;
